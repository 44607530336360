import './App.css';
import { Routes, Route } from "react-router-dom"
import Home from './pages/Home';
import EarlyAccess from './pages/EarlyAccess';
import Contract from './pages/Contract';
import GenerateContract from './pages/GenerateContract';
import ContractLogin from './pages/ContractLogin';
import PrivacyPolicy from './pages/PrivacyPolicy';

import TermsOfUse from './pages/documents/TermsOfUse';
import TokenRelease from './pages/documents/TokenRelease';
import ContactForm from './pages/ContactForm';
import LandingPage from './Landing/landing';
import BeerId from './Beerid/beerid';

function App() {

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/early-access-whitelist" element={<EarlyAccess/>} />
        <Route path="/terms-of-use" element={<TermsOfUse/>} />
        <Route path="/token-release" element={<TokenRelease></TokenRelease>} />
        <Route path="/partner" element={<Contract></Contract>} />
        <Route path="/generate-contract" element={<GenerateContract></GenerateContract>} />
        <Route path="/contract-login" element={<ContractLogin></ContractLogin>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy></PrivacyPolicy>} />
        <Route path="/contact-form" element={<ContactForm></ContactForm>} />
        <Route path="/landing" element={<LandingPage  code="E5RsvIkawxl3kTpT"></LandingPage>} />
        <Route path="/beerid" element={<BeerId></BeerId>} />
      </Routes>
    </>
  );
}

export default App;
